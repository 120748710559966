import { useEffect, useState } from 'react';

// mui
import { Box, Grid, Switch, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
// app
import { Button, Empty, ErrorMessage, Pagination, Translate } from 'components';

import * as utils from 'utils';

import { RiskRowItem } from './RiskRowItem';

const RenderObjectCards = ({
  fields,
  removeHandler,
  copyHandler,
  formProps,
  definitionsFields,
  showIncompleteOnly,
  hasError,
  display,
  productType,
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [displayFields, setDisplayFields] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(fields?.length);

  useEffect(() => {
    const filteredFields = showIncompleteOnly ? fields?.filter((_, index) => hasError(index)) : fields;
    const disFields = filteredFields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? null;
    if (disFields?.length === 0) {
      setPage(0);
    }
    setDisplayFields(disFields);
    setTotalCount(filteredFields?.length);
  }, [rowsPerPage, fields, page, showIncompleteOnly]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const openRowItemEdit = () => {
    setIsEditOpen(true);
  };

  const closeRowItemEdit = () => {
    setIsEditOpen(false);
  };

  useEffect(() => {
    if (!isEditOpen) {
      const filteredFields = showIncompleteOnly ? fields?.filter((_, index) => hasError(index)) : fields;
      const disFields = filteredFields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [];
      setDisplayFields(disFields);
      setTotalCount(filteredFields?.length);
    }
  }, [isEditOpen, showIncompleteOnly]);

  if (displayFields?.length === 0) return <Empty title={utils.string.t('app.noItems')} icon={<SearchResultImage />} width={150} />;

  return (
    <>
      {fields?.length > 1 && (
        <Box
          display="flex"
          justifyContent="justify-between"
          alignItems="center"
          flex={1}
          style={{
            flex: 1,
            width: '100%',
          }}
        >
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <Pagination
              page={page}
              count={totalCount ?? 0}
              rowsPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[9, 15, 30]}
            />
          </Box>
        </Box>
      )}
      <Grid container spacing={3} data-testid="grid-container-object-cards">
        {displayFields.map((item) => (
          <RiskRowItem
            key={item.id || `${item?.streetAddress}`}
            item={item}
            index={fields?.indexOf(item)}
            removeHandler={removeHandler}
            copyHandler={copyHandler}
            formProps={formProps}
            definitionsFields={definitionsFields}
            openRowItemEdit={openRowItemEdit}
            closeRowItemEdit={closeRowItemEdit}
            display={display}
            productType={productType}
          />
        ))}
      </Grid>
    </>
  );
};

interface IAddRiskRowMultipleObjectViewProps {
  field: any;
  formProps: any;
  showIncompleteOnly: boolean;
  handlers: {
    copyHandler: any;
    handleAddNewObj: any;
    removeHandler: any;
    appendHandler: any;
    toggleIncompleteOnly: any;
    deleteAllHandler: any;
  };
  errors: any;
  fields: any;
  productType: string;
}

export const AddRiskRowMultipleObjectView = ({
  field,
  formProps,
  showIncompleteOnly,
  handlers,
  errors,
  fields,
  productType,
}: IAddRiskRowMultipleObjectViewProps) => {
  const checkError = (index) => {
    const visibleDefs = field.arrayItemDef.filter((f) => f.type !== 'hidden');
    const hasErrors = visibleDefs.some((def) => {
      const isFieldInValid =
        errors && errors[field.name] && errors[field.name][index] && utils.generic.isValidObject(errors[field.name][index], def.name);
      return isFieldInValid;
    });
    return hasErrors;
  };

  const hasErrors = fields?.some((_, index) => checkError(index));

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2} mt={2}>
          <Typography variant="body1" style={{ fontWeight: 400, fontSize: 16 }}>
            {field.label}
          </Typography>
        </Box>
        <Box position="relative" display="flex">
          <Box mt={2}>
            <Button
              color="primary"
              size="small"
              icon={AddIcon}
              text={utils.string.t('app.add')}
              onClick={handlers.handleAddNewObj}
              data-testid="add-location-button"
            />
          </Box>
        </Box>
      </Box>

      {errors[field?.name]?.type === 'required' ? (
        <Box mb={2}>
          <ErrorMessage size="lg" hint={errors[field?.name].message} error={{}} />
        </Box>
      ) : null}

      <Box>
        {fields?.length > 1 && (
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" mb={2}>
              <Box ml={1} mr={0.5}>
                <Translate variant="body2" label="app.showIncompleteOnly" />
              </Box>
              <Switch
                size="small"
                checked={showIncompleteOnly}
                onClick={handlers.toggleIncompleteOnly}
                color="primary"
                data-testid="incompleteLocation-switch"
              />
            </Box>
            <Box sx={{ ml: 1 }}>
              <Button
                danger
                size="xsmall"
                text={utils.string.t('risks.deleteAll')}
                onClick={handlers.deleteAllHandler}
                data-testid="deleteAll-button"
                icon={DeleteIcon}
              />
            </Box>
          </Box>
        )}
        <RenderObjectCards
          fields={fields}
          removeHandler={handlers.removeHandler}
          copyHandler={handlers.copyHandler}
          formProps={formProps}
          definitionsFields={field}
          showIncompleteOnly={showIncompleteOnly}
          toggleIncompleteOnly={handlers.toggleIncompleteOnly}
          hasErrors={hasErrors}
          hasError={checkError}
          display={field.display}
          productType={productType}
        />
      </Box>
    </>
  );
};
