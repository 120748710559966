import { useGetAutocompleteOptions } from 'lib/quoteBind';

const RiskDataAutocompleteAsyncValue = ({ product, dataIndex, dataSource, valueIndex, value, partialMatch, defaultValue }) => {
  const isValueValid = typeof value === 'object';

  const { data, isLoading } = useGetAutocompleteOptions({
    product,
    dataIndex,
    dataSource,
    valueIndex,
    partialMatch,
    defaultValue,
    enabled: !isValueValid,
  });

  if (!value) return <span />;

  if (isValueValid) return <span>{value?.label}</span>;

  if (isLoading) return <span>....</span>;

  const option = data?.find((item) => item.value === value);

  return <span>{option?.label ?? value}</span>;
};

export default RiskDataAutocompleteAsyncValue;
