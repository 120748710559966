import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { showModal } from 'stores';
import * as utils from 'utils';

import { AddRiskRowMultipleObjectView } from './AddRiskRowMultipleObject.view';

const AddRiskRowMultipleObject = ({ field, formProps, productType }) => {
  const dispatch = useDispatch();
  const [showIncompleteOnly, setShowIncompleteOnly] = useState(false);

  const {
    formState: { errors },
    trigger,
  } = useFormContext();

  const { fields, append, remove, replace } = useFieldArray({
    name: field?.name,
  });

  const fieldsValues = useWatch({
    control: formProps.control,
    name: field?.name,
  });

  // abort
  if (!field || !field.name || !field.arrayItemDef) return null;
  if (!formProps || !formProps.control) return null;

  const getEmptyObject = () =>
    field?.arrayItemDef.reduce((acc, def) => {
      const defaultValue = def?.defaultValue;
      return defaultValue ? { ...acc, [def.name]: defaultValue } : acc;
    }, {}) || {};

  const appendHandler = async (obj) => {
    const formattedObj = obj;
    append(formattedObj || getEmptyObject());
  };

  const removeHandler = (idx) => {
    remove(idx);
  };

  const validateRiskRow = () => {
    trigger(field.name);
  };

  const copyHandler = (idx) => {
    const newValue = fieldsValues[idx];
    append(newValue);

    validateRiskRow();
  };

  const handleAddNewObj = () => {
    const newValue = getEmptyObject();
    appendHandler(newValue);
    validateRiskRow();
  };

  const toggleIncompleteOnly = () => {
    setShowIncompleteOnly((prev) => !prev);
  };

  const deleteAllHandler = () => {
    dispatch(
      showModal({
        type: 'CONFIRM',
        component: 'CONFIRM',
        props: {
          title: utils.string.t('risks.deleteAllConfirm'),
          subtitle: utils.string.t('risks.deleteAllConfirmWarning'),
          fullWidth: true,
          maxWidth: 'xs',
          disableEscapeKey: true,
          componentProps: {
            cancelLabel: utils.string.t('app.cancel'),
            confirmLabel: utils.string.t('app.confirm'),
            submitHandler: () => {
              replace([]);
            },
          },
        },
      })
    );
  };

  return (
    <AddRiskRowMultipleObjectView
      field={field}
      formProps={formProps}
      errors={errors}
      fields={fields}
      showIncompleteOnly={showIncompleteOnly}
      productType={productType}
      handlers={{
        copyHandler,
        handleAddNewObj,
        removeHandler,
        appendHandler,
        toggleIncompleteOnly,
        deleteAllHandler,
      }}
    />
  );
};

export default AddRiskRowMultipleObject;
