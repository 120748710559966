import { Fragment, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import isString from 'lodash/isString';
import merge from 'lodash/merge';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { FormGrid, FormLegend, ModalDialog, RiskDataAutocompleteAsyncValue, Tooltip } from 'components';
import {
  AddRiskColumn,
  AddRiskFormField,
  AddRiskObject,
  AddRiskObjectAddress,
  AddRiskRow,
  AddRiskRowMultiple,
  AddRiskRowMultipleObject,
} from 'modules';

import * as utils from 'utils';

import { HideFromUserWrapper } from './components';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 20, width: '100%' },
  text: {
    color: 'black!important',
    fontWeight: 'bold',
  },
  error: {
    color: `${theme.palette.error.main}!important`,
  },
  valid: {
    color: 'black!important',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  legend: {
    '&:not(:first-child) > legend': {
      marginTop: '10px !important',
    },
    '& > legend': {
      marginBottom: 0,
    },
  },
}));

const renderValue = (field, value, productType) => {
  let suffix = '';
  let newValue = value;

  switch (field.type) {
    case 'number':
      newValue = utils.string.t(`format.number`, { value: { number: value } });
      break;
    case 'toggle_group':
      newValue = field.options.find((option) => option.value === value)?.label;
      break;
    case 'toggle':
      newValue = utils.risk.checkBoolean(value);
      break;
    case 'checkbox':
      newValue = utils.risk.checkBoolean(value);
      break;
    case 'datepicker':
      newValue = utils.string.t(`format.date`, { value: { date: value } });
      break;
    case 'select': {
      const options = utils.generic.isValidArray(field.options, true) ? field.options : [];
      const option = options.find((o) => String(o.value) === String(value)) || {};

      newValue = option?.label !== 'Select...' ? option.label : '';
      break;
    }
    case 'autocompletemui': {
      newValue = value?.label || '';
      break;
    }
    case 'autocompletemuiAsync': {
      if (utils.generic.isValidArray(value)) {
        newValue = '';
        for (const singleValue of value) {
          newValue += `${singleValue?.label || singleValue},`;
        }
        newValue = newValue.slice(0, -1);
      } else {
        return (
          <RiskDataAutocompleteAsyncValue
            product={productType}
            dataIndex={field.dataIndex}
            dataSource={field.dataSource}
            valueIndex={field.valueIndex}
            partialMatch={field.partialMatch}
            defaultValue={field.defaultValue}
            value={value}
          />
        );
      }
      break;
    }
    case 'array': {
      newValue = value?.map((item, index) => {
        const isLastValue = index === value.length - 1;

        const filteredItem = Object.entries(item).filter(([_, v]) => v);

        const mappedItem = filteredItem.map(([key, v], itemIndex) => {
          const def = field.arrayItemDef.find(({ name }) => name === key);
          const isLastItem = itemIndex === filteredItem.length - 1;

          return (
            <Fragment key={key}>
              {renderValue(def, v)}
              {!isLastItem ? ' - ' : ''}
              {isLastItem && !isLastValue ? '; ' : ''}
            </Fragment>
          );
        });

        return mappedItem;
      });
      break;
    }
    default:
      break;
  }

  // add prefix/suffix for specific fields
  if (field.name === 'distanceToCoast' && value) {
    suffix = ` ${utils.string.t('map.unit.miles')}`;
  }
  // the extra <span /> is used to prevent Material-UI complaining about not receiving a ReactNode
  // this happens if the value is true/false/undefined/null...
  // this workaround prevents errors in case some invalid values fall through the cracks
  return utils.generic.isValidObject(newValue) ? null : (
    <span>
      {newValue}
      {suffix}
    </span>
  );
};

const EditForm = ({ item, definitionsFields, index, formProps, setValue, productType }) => {
  const classes = useStyles();
  const visibleDefs = definitionsFields.arrayItemDef.filter((f) => f.type !== 'hidden');
  const hiddenDefs = definitionsFields.arrayItemDef.filter((f) => f.type === 'hidden' || f.hideFromUser);

  useEffect(() => {
    if (definitionsFields.itemTitleField && definitionsFields.itemTitle) {
      const fieldName = `${definitionsFields.name}[${index}].${definitionsFields.itemTitleField}`;
      const fieldValue = `${definitionsFields.itemTitle} ${index + 1}`;
      setValue(fieldName, fieldValue);
    }
  }, [definitionsFields.itemTitle, definitionsFields.itemTitleField, definitionsFields.name, index, setValue]);

  const objectMap = {
    address: AddRiskObjectAddress,
    object: AddRiskObject,
  };

  return (
    <Grid container spacing={2} data-testid="grid-container" style={{ overflowY: 'auto' }}>
      <Grid item xs={12} key={item.id} style={{ margin: '20px 30px' }}>
        {hiddenDefs.map((def) => (
          <HideFromUserWrapper key={def.name ?? 'hidden'}>
            <AddRiskFormField
              field={{
                ...def,
                name: `${definitionsFields.name}[${index}].${def.name}`,
                defaultValue: item[def.name],
              }}
              formProps={formProps}
            />
          </HideFromUserWrapper>
        ))}
        <Grid container spacing={3} data-testid="grid-container">
          {visibleDefs.map((def, defIndex) => {
            const { label, ...fieldProps } = def;
            const condition = utils.risk.getCondition(def, definitionsFields.arrayItemDef);
            const conditionValue = condition && formProps.watch(`${definitionsFields.name}[${index}]`);

            const isValid = !condition || (condition && utils.risk.isConditionValid(condition, conditionValue));
            const isHidden = utils.risk.isConditionHiddenValid(def, formProps.getValues(`${definitionsFields.name}[${index}]`));
            const isDisabled = condition && !isValid;
            const hasLegend = isString(def.header);

            if (isHidden || def.hideFromUser) {
              return (
                <HideFromUserWrapper key={def.name ?? 'hidden'}>
                  <AddRiskFormField
                    field={{
                      ...fieldProps,
                      label,
                      name: `${definitionsFields.name}[${index}].${def.name}`,
                      type: 'hidden',
                      index,
                      child: def.name,
                      defaultValue: item[def.name],
                      muiComponentProps: merge(fieldProps.muiComponentProps || {}, {
                        ...(['text', 'number'].includes(def.type) && { InputProps: { disabled: isDisabled || def.disabled } }),
                      }),
                    }}
                    formProps={formProps}
                  />
                </HideFromUserWrapper>
              );
            }
            // grid spacer
            if (utils.risk.isGridSpacer(def)) {
              return (
                <FormGrid
                  item
                  xs={12}
                  {...def.gridSize}
                  nestedClasses={{ root: classes.spacer }}
                  key={`${def.group}-spacer-${idx}`}
                  data-testid={`spacer-${def.group}-${idx}`}
                >
                  <span />
                </FormGrid>
              );
            }
            // column
            if (utils.risk.isArrayColumn(def)) {
              return (
                isValid && (
                  <FormGrid item xs={12} key={`${def.group}-${def.name}`}>
                    <AddRiskColumn field={{ ...def, name: `${definitionsFields.name}[${index}].${def.name}` }} formProps={formProps} />
                  </FormGrid>
                )
              );
            }
            // row
            if (utils.risk.isArrayTable(def)) {
              return (
                isValid && (
                  <Fragment key={`${def.group}-${def.name}`}>
                    {hasLegend && (
                      <FormGrid item xs={12} nestedClasses={{ root: classes.legend }}>
                        <FormLegend text={def.header} />
                      </FormGrid>
                    )}
                    <FormGrid item xs={12}>
                      <Box mb={5}>
                        {(() => {
                          if (['SINGLECARD', 'MULTICARD'].includes(def.display)) {
                            return (
                              <AddRiskRowMultiple
                                field={def}
                                definitionsFields={definitionsFields}
                                formProps={formProps}
                                formatData="PROPERTY"
                                productType={productType}
                              />
                            );
                          }
                          if (['MULTICARD OBJECT'].includes(def.display)) {
                            return (
                              <AddRiskRowMultipleObject
                                field={def}
                                definitionsFields={definitionsFields}
                                formProps={formProps}
                                formatData="PROPERTY"
                                productType={productType}
                              />
                            );
                          }
                          return (
                            <AddRiskRow
                              field={{
                                ...def,
                                name: `${definitionsFields.name}[${index}].${def.name}`,
                                defaultValue: item[def.name],
                              }}
                              formProps={formProps}
                            />
                          );
                        })()}
                      </Box>
                    </FormGrid>
                  </Fragment>
                )
              );
            }

            // object
            if (utils.risk.isObject(def)) {
              const ObjectComponent = objectMap[def.type];

              return def?.hidden ? (
                <ObjectComponent field={def} formProps={formProps} />
              ) : isValid ? (
                <FormGrid item xs={12} key={`${def.group}-${def.name}`}>
                  <ObjectComponent field={def} formProps={formProps} />
                </FormGrid>
              ) : null;
            }

            return (
              <>
                {hasLegend && (
                  <Grid item xs={12} className={classes.legend}>
                    <FormLegend text={def.header} />
                  </Grid>
                )}
                {isValid ? (
                  <Grid item xs={6} sm={6} md={4} key={`visible-${item.id}`}>
                    <AddRiskFormField
                      field={{
                        ...fieldProps,
                        label,
                        name: `${definitionsFields.name}[${index}].${def.name}`,
                        defaultValue: item[def.name],
                        index,
                        child: def.name,
                        muiComponentProps: merge(fieldProps.muiComponentProps || {}, {
                          ...(['text', 'number'].includes(def.type) && { InputProps: { disabled: isDisabled || def.disabled } }),
                        }),
                      }}
                      formProps={formProps}
                    />
                  </Grid>
                ) : null}
              </>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function RiskRowItemView({
  index,
  isBuildingEditOpen,
  definitionsFields,
  removeHandler,
  copyHandler,
  formProps,
  handleOpenEdit,
  handleCloseEdit,
  display,
  productType,
}) {
  const classes = useStyles();
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const data = useWatch({
    control: formProps?.control,
    name: definitionsFields.name,
  });

  const itemTitleField = definitionsFields?.itemTitleField ?? 'buildingTitle';

  const item = data && data[index];

  const actions = [
    {
      name: 'ok',
      type: 'ok',
      color: 'secondary',
      label: utils.string.t('app.confirm'),
    },
  ];

  const visibleDefs = definitionsFields.arrayItemDef.filter((f) => f.type !== 'hidden' && f.type !== 'label');

  return (
    <Card raised elevation={2} className={classes.root}>
      <CardHeader
        action={
          <>
            {display !== 'SINGLECARD' && (
              <Tooltip title={`Duplicate ${definitionsFields?.itemTitle}`} placement="top">
                <IconButton aria-label="Duplicate" size="small" onClick={() => copyHandler(index)}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title={`Edit ${definitionsFields?.itemTitle}`} placement="top">
              <IconButton aria-label="Edit" size="small" onClick={handleOpenEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Delete ${definitionsFields?.itemTitle}`} placement="top">
              <IconButton aria-label="Delete" size="small" onClick={() => removeHandler(index)}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        title={`${definitionsFields?.itemTitle} ${index + 1}`}
        subheader=""
      />
      <ModalDialog
        noScroll
        actions={actions}
        title={`Edit ${item?.[itemTitleField] ? item[itemTitleField] : ''}`}
        fullWidth
        disableBackdropClick
        enableFullScreen={false}
        maxWidth="lg"
        cancelHandler={handleCloseEdit}
        hideModal={handleCloseEdit}
        visible={isBuildingEditOpen}
      >
        <EditForm
          definitionsFields={definitionsFields}
          key={index}
          item={item}
          index={index}
          formProps={formProps}
          classes={classes}
          setValue={setValue}
          errors={errors}
          productType={productType}
        />
      </ModalDialog>

      <CardContent>
        {visibleDefs.map((def) => {
          const isFieldInValid =
            errors &&
            errors[definitionsFields.name] &&
            errors[definitionsFields.name][index] &&
            utils.generic.isValidObject(errors[definitionsFields.name][index], def.name);

          const condition = utils.risk.getCondition(def, definitionsFields.arrayItemDef);
          const conditionValue = condition && formProps.watch(`${definitionsFields.name}[${index}]`);

          const isValid = !condition || (condition && utils.risk.isConditionValid(condition, conditionValue));

          return isValid ? (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6} sm={6}>
                <Typography variant="body2" component="p" classes={{ root: isFieldInValid ? classes.error : classes.valid }}>
                  {def.label}:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="body2" component="p" classes={{ root: classes.text }}>
                  {item && renderValue(def, item[def.name], productType)}
                </Typography>
              </Grid>
            </Grid>
          ) : null;
        })}
      </CardContent>
    </Card>
  );
}
