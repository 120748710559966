import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Grid, makeStyles, Switch } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { ReactComponent as SearchResultImage } from 'assets/svg/search-result.svg';
// app
import { Button, Empty, ErrorMessage, LocationAutocomplete, Pagination, Translate } from 'components';

import * as utils from 'utils';

import { RiskRowItem } from './RiskRowItem';

const useStyles = makeStyles(() => ({
  buttonAdding: {
    paddingLeft: '30px!important',
  },
  buttonProgress: {
    position: 'absolute',
    top: 15,
    left: 20,
  },
}));

const RenderObjectCards = ({
  fields,
  removeHandler,
  copyHandler,
  formProps,
  definitionsFields,
  showIncompleteOnly,
  hasError,
  display,
  productType,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [displayFields, setDisplayFields] = useState([]);
  const [isBuildingEditOpen, setIsBuildingEditOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(fields?.length);

  useEffect(() => {
    const filteredFields = showIncompleteOnly ? fields?.filter((_, index) => hasError(index)) : fields;
    const disFields = filteredFields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? null;
    if (disFields?.length === 0) {
      setPage(0);
    }
    setDisplayFields(disFields);
    setTotalCount(filteredFields?.length);
  }, [rowsPerPage, fields, page, showIncompleteOnly]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const openRowItemEdit = () => {
    setIsBuildingEditOpen(true);
  };

  const closeRowItemEdit = () => {
    setIsBuildingEditOpen(false);
  };

  useEffect(() => {
    if (!isBuildingEditOpen) {
      const filteredFields = showIncompleteOnly ? fields?.filter((_, index) => hasError(index)) : fields;
      const disFields = filteredFields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [];
      setDisplayFields(disFields);
      setTotalCount(filteredFields?.length);
    }
  }, [isBuildingEditOpen, showIncompleteOnly]);

  if (displayFields?.length === 0 && display === 'MULTICARD')
    return <Empty title={utils.string.t('app.noItems')} icon={<SearchResultImage />} width={150} />;

  return (
    <>
      {display === 'MULTICARD' && fields?.length > 1 && (
        <Box
          display="flex"
          justifyContent="justify-between"
          alignItems="center"
          flex={1}
          style={{
            flex: 1,
            width: '100%',
          }}
        >
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <Pagination
              page={page}
              count={totalCount ?? 0}
              rowsPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[9, 15, 30]}
            />
          </Box>
        </Box>
      )}
      <Grid container spacing={3} data-testid="grid-container-object-cards">
        {displayFields.map((item) => (
          <RiskRowItem
            key={item.id || `${item?.streetAddress}`}
            item={item}
            index={fields?.indexOf(item)}
            showIncompleteOnly={showIncompleteOnly}
            hasError={hasError}
            removeHandler={removeHandler}
            copyHandler={copyHandler}
            formProps={formProps}
            definitionsFields={definitionsFields}
            openRowItemEdit={openRowItemEdit}
            closeRowItemEdit={closeRowItemEdit}
            display={display}
            productType={productType}
          />
        ))}
      </Grid>
    </>
  );
};

export const AddRiskRowMultipleView = ({
  isAdding,
  field,
  formProps,
  showIncompleteOnly,
  handlers,
  errors,
  fields,
  showDownloadExcelTemplate,
  productType,
}) => {
  const classes = useStyles();
  const checkError = (index) => {
    const visibleDefs = field.arrayItemDef.filter((f) => f.type !== 'hidden');
    const hasErrors = visibleDefs.some((def) => {
      const isFieldInValid =
        errors && errors[field.name] && errors[field.name][index] && utils.generic.isValidObject(errors[field.name][index], def.name);
      return isFieldInValid;
    });
    return hasErrors;
  };

  const hasErrors = fields?.some((_, index) => checkError(index));
  const prepopulatedFields = field.arrayItemDef.filter((item) => item.prepopulated);
  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <LocationAutocomplete
          title={field?.label ? field?.label : "utils.string.t('products.multiLocation.label')"}
          buttonLabel={utils.string.t('app.addLocation')}
          textFieldPlaceholder={utils.string.t('products.multiLocation.placeholder')}
          handleAdd={handlers.handleAddNewObj}
          componentRestrictions={field?.componentRestrictions}
          getDTC
          prepopulatedFields={prepopulatedFields}
          isDisabled={field.display === 'SINGLECARD' && fields?.length === 1}
        />
        {field.display === 'MULTICARD' && (
          <Box position="relative" display="flex">
            <Box ml={1.5} mt={2}>
              <Button
                color="primary"
                size="small"
                variant="outlined"
                icon={isAdding ? null : FileCopyIcon}
                text={isAdding ? utils.string.t('app.addingLocations') : utils.string.t('app.pasteFromExcel')}
                disabled={isAdding}
                nestedClasses={isAdding ? { btn: classes.buttonAdding } : {}}
                onClick={() =>
                  handlers.launchPasteFromExcelModal({
                    name: field.name,
                    headers: field.arrayItemDef.reduce(
                      (acc, def) => (def.type === 'hidden' ? [...acc] : [...acc, { key: def.name, value: '' }]),
                      []
                    ),
                    handlers: {
                      submit: (data) => {
                        handlers.appendHandler(data, true);
                        handlers.closePasteFromExcelModal();
                      },
                    },
                  })
                }
              />
            </Box>
            {showDownloadExcelTemplate ? (
              <Box ml={1.5} mt={2}>
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  icon={GetAppIcon}
                  text={utils.string.t('app.downloadExcelTemplate')}
                  onClick={() => handlers.handleDownloadExcelTemplate()}
                />
              </Box>
            ) : null}
            {isAdding ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
            {field.displaySovImport && (
              <Box ml={1.5} mt={2}>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  icon={ImportExportIcon}
                  text={utils.string.t('app.importSOV')}
                  onClick={handlers.handleImportSOV}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>

      {errors[field?.name]?.type === 'required' ? (
        <Box mb={2}>
          <ErrorMessage size="lg" hint={errors[field?.name].message} error={{}} />
        </Box>
      ) : null}

      <Box>
        {fields?.length > 1 && (
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" mb={2}>
              <Box ml={1} mr={0.5}>
                <Translate variant="body2" label="app.showIncompleteOnly" />
              </Box>
              <Switch
                size="small"
                checked={showIncompleteOnly}
                onClick={handlers.toggleIncompleteOnly}
                color="primary"
                data-testid="incompleteLocation-switch"
              />
            </Box>
            <Box sx={{ ml: 1 }}>
              <Button
                danger
                size="xsmall"
                text={utils.string.t('risks.deleteAll')}
                onClick={handlers.deleteAllHandler}
                data-testid="deleteAll-button"
                icon={DeleteIcon}
              />
            </Box>
          </Box>
        )}
        <RenderObjectCards
          fields={fields}
          removeHandler={handlers.removeHandler}
          copyHandler={handlers.copyHandler}
          formProps={formProps}
          definitionsFields={field}
          showIncompleteOnly={showIncompleteOnly}
          toggleIncompleteOnly={handlers.toggleIncompleteOnly}
          hasErrors={hasErrors}
          hasError={checkError}
          display={field.display}
          productType={productType}
        />
      </Box>
    </>
  );
};

AddRiskRowMultipleView.propTypes = {
  field: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  validFields: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  overflow: PropTypes.bool,
  formatData: PropTypes.string,
  handlers: PropTypes.shape({
    launchPasteFromExcelModal: PropTypes.func.isRequired,
    closePasteFromExcelModal: PropTypes.func.isRequired,
    toggleIncompleteOnly: PropTypes.func,
    handleDownloadExcelTemplate: PropTypes.func,
    copyHandler: PropTypes.func,
    removeHandler: PropTypes.func,
    appendHandler: PropTypes.func,
    handleImportSOV: PropTypes.func,
  }),
};
