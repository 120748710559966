import { Box } from '@material-ui/core';

export const HideFromUserWrapper = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      width: 1,
      height: 1,
      padding: 0,
      margin: -1,
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      whiteSpace: 'nowrap',
      border: 0,
      clipPath: 'inset(50%)',
    }}
  >
    {children}
  </Box>
);
