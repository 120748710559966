import { useState } from 'react';

import { Grid } from '@material-ui/core';

import RiskRowItemView from './RiskRowItem.view';

export const RiskRowItem = ({
  item,
  index,
  definitionsFields,
  formProps,
  removeHandler,
  copyHandler,
  openRowItemEdit,
  closeRowItemEdit,
  display,
  productType,
}) => {
  const [isBuildingEditOpen, setIsBuildingEditOpen] = useState(false);
  const handleOpenEdit = () => {
    setIsBuildingEditOpen(true);
    openRowItemEdit();
  };

  const handleCloseEdit = () => {
    setIsBuildingEditOpen(false);
    closeRowItemEdit();
  };

  return (
    <Grid container xs={12} sm={6} md={4} item key={item.id || item?.streetAddress}>
      <RiskRowItemView
        index={index}
        isBuildingEditOpen={isBuildingEditOpen}
        removeHandler={removeHandler}
        copyHandler={copyHandler}
        definitionsFields={definitionsFields}
        formProps={formProps}
        handleOpenEdit={handleOpenEdit}
        handleCloseEdit={handleCloseEdit}
        display={display}
        productType={productType}
      />
    </Grid>
  );
};
