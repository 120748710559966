import { useQuery } from 'react-query';

import * as utils from 'utils';

import { quoteBindAxios } from '../quoteBind.api';

export const useGetAutocompleteOptions = ({
  product,
  dataIndex,
  dataSource,
  valueIndex,
  restrictBy,
  includeCustom,
  partialMatch,
  defaultValue,
  enabled,
}) =>
  useQuery({
    queryKey: ['autocompleteOptions', product, dataIndex, dataSource, valueIndex],
    queryFn: async () => {
      const response = await quoteBindAxios.get(`products/${product}/data/${dataIndex}`, {
        params: {
          dataSource,
          valueIndex,
          ...(!!restrictBy && { restrictBy: encodeURIComponent(restrictBy) }),
          ...(!!includeCustom && { includeCustom }),
          ...(typeof partialMatch === 'boolean' && { partialMatch }),
          ...(dataSource && defaultValue && { defaultValue }),
        },
      });
      return response.data;
    },
    onError: (error) => {
      const errorParams = {
        file: 'FormAutocompleteMuiAsync.js',
        message: 'API fetch error, initial fetchData',
      };

      utils.api.handleError(error, errorParams);
    },
    enabled,
  });
